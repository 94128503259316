import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './locales/i18next';
import { Checkout } from './Checkout';
import reportWebVitals from './reportWebVitals';
import { initializeDatadog } from './utils/monitoring/Datadog';
import { FlagProvider } from '@unleash/proxy-client-react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

const fpConfig = {
    url: 'https://eu.app.unleash-hosted.com/cyan/api/frontend/',
    clientKey: '*:default.eeb4bcda7b28f51f0198cff782b769cca65fd04eb0f352b8b55cf300',
    refreshInterval: 30,
    appName: 'ecommerce-app',
    environment: process.env.REACT_APP_DATATRANS_ENV
};

ReactDOM.render(
    <BrowserRouter basename="/checkout">
        <FlagProvider config={fpConfig} startClient={false}>
            <Checkout />
        </FlagProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

reportWebVitals();

/**
 * Initializes Datadog Real User Monitoring (RUM)
 */
if (process.env.REACT_APP_DATADOG_ENV) {
    initializeDatadog();
}
