import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import jwtDecode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faL, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@tampmd/hyperion-ui/Button';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { CheckoutContext } from '../../../hooks/CheckoutContext';
import { AddressFormType, Country, FieldType, FormContext } from '../../../hooks/FormContext';
import { BffServiceContext } from '../../../hooks/BffServiceContext';
import { OrderContext } from '../../../hooks/OrderContext';
import { NotificationContext } from '../../../hooks/NotificationContext';
import { CustomerContext } from '../../../hooks/CustomerContext';
import { PaymentContext } from '../../../hooks/PaymentContext';
import { OfferContext } from '../../../hooks/OfferContext';
import { AuthenticationContext } from '../../../hooks/AuthenticationContext';
import { TenantContext } from '../../../hooks/TenantContext';
import { getTransformedAddressData, getTransformedOrderAddressData } from '../../../services/Address/Address';
import { addressNameEnum, AddressType, AddressWithSapCustomerNumber, PostAddressData } from '../../../types/types';
import { SelectAddressComponent } from './SelectAddressComponent';
import { GatewayAddressService } from '../../../services/GatewayAddressService/GatewayAddressService';
import { InvalidAddressDialog } from './invalidAddressDialog';

export const StepActions = (): JSX.Element =>
{
    const [ values, , countries, setIsAddressUpdated ]: any = useContext(FormContext);
    const { t } = useTranslation();
    const [ step, setStep, stepContent ]: any = useContext(CheckoutContext);
    const { bffService } = useContext(BffServiceContext);
    const { order } = useContext(OrderContext);
    const { setNotification } = useContext(NotificationContext);
    const { customer, setCustomer } = useContext(CustomerContext);
    const { payment } = useContext(PaymentContext);
    const { idToken: auth } = useContext(AuthenticationContext);
    const { isGiftOffer } = useContext(OfferContext);
    const { tenantState: [ tenant ] }: any = useContext(TenantContext);
    const gatewayAddressService = new GatewayAddressService(auth);
    const [toggleAddress, setToggleAddress] = useState(false);
    const [toggleAddressDialog, setToggleAddressDialog] = useState(false);
    const [postApiAddresses, setPostApiAdresses] = useState<PostAddressData[]>([{   
        firstname: '',
        lastname: '',
        zip: '',
        city: '',
        country: '',
        street: '',
        housenumber: ''
    }]);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const getCountryName = (countryCode: string) => {
        const countryObject = countries.find((country: Country) => country.value === countryCode);

        return countryObject?.name;
    };

    const createCustomerWithAddress = async (addressData: AddressType) =>
    {
        try {
            if (order?.code && bffService) {
                setIsLoading(true);
                const response = await bffService.createCustomer(order.code, addressData);

                if (response?.data.status === 'success') {
                    const { data: { data: customer }} = await bffService.getCustomer();

                    setCustomer(customer);
                    setStep(step + 1);
                }
            }
        } catch (error: any) {
            const { response: { data: errorResponse, status }} = error;
            const { message } = errorResponse.error || errorResponse.data;

            if (status === 409) {
                setNotification({
                    status: 'danger',
                    description: `${t('address.form.error.duplicateData')}`
                });
            } else {
                setNotification({
                    status: 'danger',
                    description: message
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const updateAddress = async (addressData: AddressWithSapCustomerNumber) =>
    {
        try {
            const updateAddressData = {
                ...addressData,
                address: {
                    ...addressData.address,
                    countryName: getCountryName(addressData.address.land1)
                },
                orderCode: order.code.toString()
            };

            setIsLoading(true);
            const responseUpdateAddress = await bffService?.updateAddress(updateAddressData);

            const responseUpdateAddressStatus = responseUpdateAddress?.data.status;

            if (responseUpdateAddressStatus === 'success') {
                setIsAddressUpdated(true);
                setStep(step + 1);
            } else if (responseUpdateAddressStatus === 'post-processing') {
                setNotification({
                    status: 'warning',
                    description: `${t('address.form.notificationPostProcessing')}`
                });
                setStep(step + 1);
            }
        } catch (error: any) {
            const { response: { data: errorResponse, status }} = error;
            const { message } = errorResponse.error || errorResponse.data;

            if (status === 400 && message === 'Request data was not valid.') {
                setNotification({
                    status: 'danger',
                    description: `${t('address.form.error.dataNotValid.partOne')}`,
                    linkText: `${t('address.form.error.dataNotValid.customerService')}`,
                    link: tenant.contact.onlineForm
                });
            } else {
                setNotification({
                    status: 'danger',
                    description: message
                });
            }
        } finally {
            setIsLoading(false);
        }
    };
    // eslint-disable-next-line
    const validateAddress = async (addressData: any): Promise<PostAddressData[] | undefined> => {
        try {
            setIsLoading(true);
            const validatedAddress: PostAddressData[] = (await gatewayAddressService.validateAddress(addressData)).data;

            return validatedAddress;
        } catch (error: any){
            if (error.response?.data?.message === 'Invalid address') {
                setToggleAddressDialog(true);
            } else {
                switchStep();
            }
        } finally {
            setIsLoading(false);
        }
    };
    

    const switchStep = async () => {
        const decodedIdToken: any = jwtDecode(auth);
        const { email } = decodedIdToken;

        switch (step) {
        case 1: {
            const addressData = getTransformedAddressData(values.address, email);

            if (!customer?.sapId) {
                if (!values.addDeliveryAddress) {
                    await createCustomerWithAddress({
                        address: addressData
                    });
                } else if (isGiftOffer || values.addDeliveryAddress) {
                    setStep(step + 1);
                }
            } else {
                const { sapCustomerNumber, paymentAddress } = order;

                const transformedOrderPaymentAddress = getTransformedOrderAddressData(paymentAddress);
                const orderPaymentAddress = lodash.omit(transformedOrderPaymentAddress, ['_id']);

                //* Check if this is ok for the addressData how was it before address updates!!!!
                const isAddressChanged = !lodash.isEqual(addressData, orderPaymentAddress);

                if (isAddressChanged) {
                    const updateAddressData = {
                        address: {
                            ...addressData,
                            addressId: paymentAddress?.addressId,
                            addressIdSf: '',
                            sapCustomerNumber,
                            type: addressNameEnum.contractingAddress
                        }
                    };

                    await updateAddress(updateAddressData);
                } else {
                    setStep(step + 1);
                }
            }
            break;
        }
        case 2: {
            const deliveryAddressData = getTransformedAddressData(values.deliveryAddress, email);

            if (values.addDeliveryAddress && !customer?.sapId) {
                const addressData = getTransformedAddressData(values.address, email);

                await createCustomerWithAddress({
                    address: addressData,
                    deliveryAddress: deliveryAddressData
                });
            } else if (stepContent.length === 4) {
                const { sapCustomerNumber, deliveryAddress } = order;

                const transformedOrderDeliveryAddress = getTransformedOrderAddressData(deliveryAddress);

                const orderDeliveryAddress = lodash.omit(transformedOrderDeliveryAddress, ['_id']);
                const isAddressChanged = !lodash.isEqual(deliveryAddressData, orderDeliveryAddress);

                if (isAddressChanged) {
                    const updateAddressData = {
                        address: {
                            ...deliveryAddressData,
                            addressId: deliveryAddress?.addressId,
                            addressIdSf: '',
                            sapCustomerNumber,
                            type: addressNameEnum.deliveryAddress
                        }
                    };

                    await updateAddress(updateAddressData);
                } else {
                    setStep(step + 1);
                }
            } else {
                setStep(step + 1);
            }
            break;
        }
        default:
            setStep(step + 1);
        }
    };

    const updateValuesWithPostApiAddress = async (postApiAddressData: PostAddressData) => {
        if ( step === 1) {
            values.address.street.value = postApiAddressData.street;
            values.address.city.value = postApiAddressData.city;
            values.address.zipCode.value = postApiAddressData.zip;
            values.address.streetNumber.value = postApiAddressData.housenumber;
        } else if (step === 2) {
            values.deliveryAddress.street.value = postApiAddressData.street;
            values.deliveryAddress.city.value = postApiAddressData.city;
            values.deliveryAddress.zipCode.value = postApiAddressData.zip;
            values.deliveryAddress.streetNumber.value = postApiAddressData.housenumber;
        }
        setToggleAddress(false);
        await switchStep();
    };

    const checkCompleteMatchingOfFields = (postApiAddressData: PostAddressData): boolean => {
        if ( (step === 1 &&
            values.address.street.value === postApiAddressData.street &&
            values.address.city.value === postApiAddressData.city &&
            values.address.zipCode.value === postApiAddressData.zip &&
            values.address.streetNumber.value === postApiAddressData.housenumber) || (step === 2 &&
            values.deliveryAddress.street.value === postApiAddressData.street &&
            values.deliveryAddress.city.value === postApiAddressData.city &&
            values.deliveryAddress.zipCode.value === postApiAddressData.zip &&
            values.deliveryAddress.streetNumber.value === postApiAddressData.housenumber))
        {
            return true;
        }
        
        return false; 
    };

    const handleOnClick = async (): Promise<void> =>
    {
        const decodedIdToken: any = jwtDecode(auth);
        const { email } = decodedIdToken;
        let addressData;

        if (step === 1) {
            addressData = getTransformedAddressData(values.address, email);
        } else if (step === 2) {
            addressData = getTransformedAddressData(values.deliveryAddress, email);
        }
        if (addressData?.land1 !== 'CH') {
            switchStep();
        } else {
            const validatedAddress = await validateAddress(addressData);

            if (validatedAddress && validatedAddress.length === 1 && checkCompleteMatchingOfFields(validatedAddress[0])){
                updateValuesWithPostApiAddress(validatedAddress[0]);
            } else if (validatedAddress && validatedAddress.length){
                const address = step === 1 ? values.address : values.deliveryAddress;

                validatedAddress.unshift({
                    street: address.street.value,
                    city: address.city.value,
                    zip: address.zipCode.value,
                    housenumber: address.streetNumber.value,
                    country: address.country.value
                });
                setPostApiAdresses(validatedAddress);
                setToggleAddress(true);
            } 
        }
    };

    const isRequiredFieldOmitted = (formValues:any): boolean => formValues.some((formValue: FieldType) =>
    {
        const { value, isRequired } = formValue;

        return isRequired && (typeof value === 'string' && !value.trimStart());
    });

    const isFormUncompleted = (addressValues: AddressFormType): boolean => {
        const { postOffBox: { value: postOffBoxValue }} = addressValues;
        const { street: { value: streetValue }} = addressValues;
        const { postOffBoxNoNr: { value: postOffBoxNoNrValue }} = addressValues;
        const { street, ...addressValuesWithoutStreet } = addressValues;
        const formValues: FieldType[] = Object.values(addressValuesWithoutStreet);
        const isUncompleted = isRequiredFieldOmitted(formValues);

        return isUncompleted || (!streetValue && !postOffBoxValue && !postOffBoxNoNrValue);
    };

    const isValidSwissZipCode = (currentStep: number): boolean =>
    {
        let validSwissZipCode = true;

        const formValues = currentStep === 1 ? values['address'] : values['deliveryAddress'];
        const { country: { value: currentSelectedCountry }, zipCode: { value: currentZipCodeValue }} = formValues;

        if (currentSelectedCountry === 'CH' && currentZipCodeValue.trimStart().length > 4) {
            validSwissZipCode = false;
        }

        return validSwissZipCode;
    };

    const isValidAddressCompletion = (currentStep: number): boolean =>
    {
        let validAddressCompletion = true;

        const formValues = currentStep === 1 ? values['address'] : values['deliveryAddress'];
        const { addressCompletion: { value: currentAddressCompletionValue }} = formValues;

        if (currentAddressCompletionValue.trimStart().length > 35) {
            validAddressCompletion = false;
        }

        return validAddressCompletion;
    };

    const isNextButtonDisabled = (): boolean =>
    {
        let isDisabled = false;
        const isAddressFormUncompleted =
            isFormUncompleted(values.address) || !isValidSwissZipCode(1) || !isValidAddressCompletion(1);

        const isDeliveryAddressFormUncompleted =
            isFormUncompleted(values.deliveryAddress) || !isValidSwissZipCode(2) || !isValidAddressCompletion(2);

        const noPaymentSelected = step === stepContent.length - 1 && payment === '';
        const addressFormUncompleted = (step === 1 && isAddressFormUncompleted)
            || (step === 2 && values.addDeliveryAddress && isDeliveryAddressFormUncompleted);

        if (noPaymentSelected || addressFormUncompleted || isLoading) {
            isDisabled = true;
        }

        return isDisabled;
    };

    return (        
        <Paper classes={['d-flex', 'justify-content-end', 'my-4']}>
            {
                (step > 1 && step < stepContent.length) &&
                <Button onClick={() => setStep(step - 1)} autoWidth variant="secondary" classes={['mr-3']}>
                    {t('back')}
                </Button>
            }
            <Button
                custom
                autoWidth
                onClick={() => handleOnClick()}
                disabled={isNextButtonDisabled()}
            >
                <Paper classes={['d-flex', 'align-items-center', 'justify-content-center']}>
                    <Typography variant="text" color={isNextButtonDisabled() ? 'gray' : 'white'}>
                        {t('next')}
                    </Typography>
                    {isLoading && <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin ml-2"/>}
                </Paper>
            </Button>
            <Paper>
                {toggleAddress && <SelectAddressComponent toggleAddress={toggleAddress} setToggleAddress={setToggleAddress} addresses={postApiAddresses} switchStep={switchStep} updateValuesWithPostApiAddress={updateValuesWithPostApiAddress} />}
            </Paper>
            <Paper>
                {toggleAddressDialog && <InvalidAddressDialog toggleAddressDialog={toggleAddressDialog} setToggleAddressDialog={setToggleAddressDialog} address={step === 1 ? values.address : values.deliveryAddress} switchStep={switchStep} />}
            </Paper>
        </Paper>
    );
};
