export const TRANSLATIONS_DE = {
    'address': {
        'title': 'Adresse',
        'private': 'Privatadresse',
        'delivery': 'Lieferanschrift',
        'giftDelivery': 'Adresse Geschenkempfänger',
        'company': {
            'title': 'Firmenadresse',
            'message': {
                'partOne': 'Um ein Firmenkonto einzurichten, kontaktieren Sie bitte unsere Kundendienst via ',
                'partTwo': ' oder unter '
            }
        },
        'form': {
            'title': 'Adresse ändern',
            'field': {
                'salutation': 'Anrede',
                'mr': 'Herr',
                'mrs': 'Frau',
                'name': 'Vorname',
                'surname': 'Nachname',
                'street': 'Strasse',
                'streetNumber': 'Hausnummer',
                'addressCompletion': 'Adressergänzung',
                'postOffBox': 'Postfach',
                'postOffBoxNoNr': 'Postfach ohne Nummer',
                'zipCode': 'Postleitzahl',
                'city': 'Ort',
                'country': 'Land auswählen',
                'phone': 'Telefonnummer (+41)',
                'addDeliveryAddress': 'Die Lieferung erfolgt an eine andere Adresse.'
            },
            'validation': {
                'zipCode': 'Bitte überprüfen Sie die Postleitzahl',
                'addressCompletion': 'Die Adressergänzung darf nicht mehr als 35 Zeichen lang sein. Bitte nutzen sie Abkürzungen wo  möglich.'
            },
            'infoMessage': 'Postfach',
            'infoMessageText': 'Falls Sie eine Strasse eingegeben haben, kann kein Postfach ausgewählt werden. Durch Entfernen der Strasse kann das Postfach eingetragen werden.',
            'notificationPostProcessing': 'Angeforderte Adressänderung wird bearbeitet.',
            'error': {
                'duplicateData': 'Ihre Adressdaten sind bereits in unserem System vorhanden. Bitte melden Sie sich bei unserem Kundendienst um die Bestellung abzuschliessen.',
                'dataNotValid': {
                    'partOne': 'Ihre Adresse befindet sich in der Nachbearbeitung. Eine Bestellung kann erst erfasst werden wenn die Adresse in unserem System angepasst wurde. Bitte kontaktieren Sie unseren',
                    'customerService': 'Kundendienst.'
                }
            },
            'addressConfirmation': "Sind Sie sicher, dass sie die richtige Adresse angegeben haben?",
            'addressDropdown': 'Bitte wählen Sie eine Adresse aus dem Auswahlmenü',
            'continueButton': 'weiter'
        }
    },
    'payment': {
        'title': 'Zahlungsmethode',
        'for': 'Zahlungen für',
        'changeSuccess': 'Zahlungsmethode wurde erfolgreich hinterlegt.',
        'changeFailed': 'Leider konnte Ihr  Zahlungsmittel nicht geändert werden. Bitte versuchen Sie zu einem späteren Zeitpunkt erneut oder kontaktieren Sie unseren Kundendienst.',
        'datatransFailed': 'Leider konnte Ihr Zahlungsmittel nicht eingetragen werden. Bitte versuchen Sie zu einem späteren Zeitpunkt erneut oder kontaktieren Sie unseren Kundendienst.',
        'popup': {
            'title': 'Zahlungsmethode ändern',
            'sideText': 'Ausgewählt',
            'method': {
                'creditcard': 'Kreditkarte',
                'bill': 'Rechnung'
            }
        },
        'invoice': 'Rechnung',
        'selected': 'Ausgewählt'
    },
    'summary': {
        'title': 'Zusammenfassung',
        'month': 'Monat',
        'desc': 'Die Zeitung von Montag bis Samstag inklusive unbegrenztem digitalen Zugang.',
        'total': 'Gesamt',
        'after': 'Danach',
        'year': 'Jahr',
        'accept': {
            'partOne': 'Durch Klicken auf "Bestellung abschicken" stimmen Sie den',
            't&c': 'AGB',
            'and1': 'und der',
            'and2': 'zu',
            'dataProtection': 'Datenschutzerklärung'
        },
        'submit': 'Bestellung abschicken',
        'deliveryDate': 'Liefertermin',
        'selectDate': 'Liefertermin auswählen',
        'discount': 'Rabatt',
        'shipping': 'Versandkosten'
    },
    'button': {
        'change': 'Bearbeiten',
        'cancel': 'Stornieren',
        'apply': 'Hinzufügen'
    },
    'footer': {
        'phone': {
            'title': 'Haben Sie Fragen?',
            'subTitle': 'Rufen Sie uns an, wir helfen Ihnen gerne persönlich weiter.'
        },
        'email': {
            'title': 'Kontaktieren Sie uns',
            'subTitle': 'Kontaktformular'
        },
        'SSL': {
            'title': 'Sicherer Bestellvorgang',
            'subTitle': {
                'partOne': 'Ihre Angaben werden mit einem',
                'partTwo': 'SSL-verschlüsselten Zertifikat übertragen.'
            }
        }
    },
    'classic': 'Classic',
    'checkout': 'Checkout',
    'next': 'Weiter',
    'back': 'Zurück',
    'price': 'Preis',
    'thankYou': {
        'title': 'Dankeschön',
        'postprocessing': 'Aufgrund einer Unstimmigkeit in Ihrer Rechnungs- oder Lieferadresse muss Ihre Bestellung manuell überprüft werden. Sobald Ihre Bestellung bearbeitet wurde, können Sie Ihr Abonnement in Ihrem Konto einsehen und Ihren Digitalzugriff nutzen.',
        'subtitle': 'Ihre Bestellung wurde erfolgreich abgeschlossen.',
        'text': 'Eine E-Mail-Bestätigung mit den Details zu Ihrer Bestellung wurde an die angegebene E-Mail-Adresse gesendet. Bitte bewahren Sie es für Ihre Unterlagen auf.',
        'myAccountBtn': 'Weiter zu Mein Konto',
        'startPageBtn': 'Weiter zu',
        'bannerText': {
            'part1': 'Mehr aus dem Abo holen und',
            'part2': 'App',
            'part3': 'installieren'
        },
        'bannerBtnText': {
            'version1': 'Zur App',
            'version2': 'Zur E-Paper-App'
        }
    },
    'errorPage': {
        'text': 'Ihr Bestellung konnte leider nicht übermittelt werden. Bitte versuchen Sie zu einem späteren Zeitpunkt erneut oder kontaktieren Sie unseren Kundendienst.',
        'button': 'Wiederholen'
    },
    'notFoundPage': {
        'text': 'Die Seite ist umgezogen. Hier gehts zum <0>Shop</0>.'
    },
    'gift': 'geschenk',
    'campagin': 'kampagne',
    'maintenanceBanner': {
        'title': 'Wartungsarbeiten',
        'text': 'Diese Website ist  aufgrund geplanter Wartungsarbeiten vorübergehend nicht erreichbar. Die Unterbrechung sollte nur kurzzeitig sein. Bei Fragen können Sie gerne unser Kontaktformular nutzen, um den Kundendienst zu erreichen, oder es zu einem späteren Zeitpunkt erneut versuchen. Wir bitten um Ihr Verständnis und entschuldigen uns für die Unannehmlichkeiten.',
        'contactFormBtn': 'Kontaktformular',
        'startPageBtn': 'Weiter zu',
    }
};
