export const TRANSLATIONS_FR = {
    'address': {
        'title': 'Adresse',
        'private': 'Adresse privée',
        'delivery': 'Adresse de livraison',
        'giftDelivery': 'Adresse de livraison du cadeau',
        'company': {
            'title': 'Adresse de la société',
            'message': {
                'partOne': 'Pour créer un compte entreprise, veuillez contacter notre service client via ',
                'partTwo': ' ou sur '
            }
        },
        'form': {
            'title': 'Changement d\'adresse',
            'field': {
                'salutation': 'Civilité',
                'mr': 'Monsieur',
                'mrs': 'Madame',
                'name': 'Prénom',
                'surname': 'Nom',
                'street': 'Rue',
                'streetNumber': 'N°',
                'addressCompletion': 'Complément d\’adresse',
                'postOffBox': 'Case postale',
                'postOffBoxNoNr': 'Case postale sans numéro',
                'zipCode': 'NPA',
                'city': 'Lieu',
                'country': 'Choisissez un pays',
                'phone': 'Numéro de téléphone (+41)',
                'addDeliveryAddress': 'La livraison sera effectuée à une adresse différente.',
            },
            'validation': {
                'zipCode': 'Veuillez vérifier le code postal',
                'addressCompletion': 'Le complément d\'adresse ne doit pas dépasser 35 caractères. Veuillez utiliser des abréviations dans la mesure du possible.'
            },
            'infoMessage': 'Case postale',
            'infoMessageText': 'Il n’est pas possible de renseigner une adresse ainsi qu’une Case postale. En supprimant l’adresse vous serez en mesure de renseigner une Case postale.',
            'notificationPostProcessing': 'Le changement d\'adresse demandé est en cours de traitement.',
            'error': {
                'duplicateData': "Votre adresse est déjà enregistrée dans notre système. Veuillez vous connecter avec vos données de connexion et repasser votre commande.",
                'dataNotValid': {
                    'partOne': 'Votre adresse est en cours de traitement. Une commande ne peut être saisie que lorsque l\'adresse a été corrigée dans notre système. Nous vous prions de contacter notre',
                    'customerService': 'service clientèle.'
                }
            },
            'addressConfirmation': "Êtes-vous sûr d'avoir indiqué l'adresse correcte?",
            'addressDropdown': 'Veuillez choisir une adresse dans le menu de sélection',
            'continueButton': 'continuer'
        }
    },
    'payment': {
        'title': 'Mode de Paiement',
        'for': 'Paiements pour',
        'changeSuccess': 'Le mode de paiement a été déposé avec succès.',
        'changeFailed': "Malheureusement, votre moyen de paiement n'a pas pu être modifié. Merci de réessayer plus tard ou de contacter notre service clientèle.",
        'datatransFailed': "Malheureusement, votre moyen de paiement n'a pas pu être enregistré. Veuillez réessayer ultérieurement ou contacter notre service clientèle.",
        'popup': {
            'title': 'Changer la méthode de paiement',
            'sideText': 'Choisi',
            'method': {
                'creditcard': 'Carte de crédit',
                'bill': 'Facture'
            }
        },
        'invoice': 'Facture',
        'selected': 'Choisi'
    },
    'summary': {
        'title': 'Résumé',
        'month': 'mois',
        'desc': 'Le journal du lundi au samedi avec accès numérique illimité.',
        'total': 'Le total',
        'after': 'Après ça',
        'year': 'an',
        'accept': {
            'partOne': 'En cliquant sur \'Soumettre la commande\', vous acceptez aux',
            't&c': 'CGV',
            'and1': 'et à la',
            'and2': '',
            'dataProtection': 'politique de confidentialité'
        },
        'submit': 'Soumettre la commande',
        'deliveryDate': 'Date de livraison',
        'selectDate': 'Sélectionnez la date de livraison',
        'discount': 'Remise',
        'shipping': 'Les frais de livraison'
    },
    'button': {
        'change': 'Changement',
        'cancel': 'Annuler',
        'apply': 'Appliquer'
    },
    'footer': {
        'phone': {
            'title': 'Des questions?',
            'subTitle': 'Contactez-nous.'
        },
        'email': {
            'title': 'Contactez-nous.',
            'subTitle': 'Formulaire de contact'
        },
        'SSL': {
            'title': 'Processus de commande sécurisé',
            'subTitle': {
                'partOne': 'Vos données sont sécurisées',
                'partTwo': 'avec un certificat crypté SSL.'
            }
        }
    },
    'classic': 'Intégral',
    'checkout': 'Checkout',
    'next': 'continuer',
    'back': 'retourner',
    'price': 'Prix',
    'thankYou': {
        'title': 'Merci',
        'postprocessing': 'En raison d\'une irrégularité dans votre adresse de facturation ou de livraison, votre commande doit être vérifiée manuellement. Une fois que votre commande a été traitée, vous pouvez consulter votre abonnement dans votre compte et utiliser votre accès numérique.',
        'subtitle': 'Votre commande a été complétée avec succès.',
        'text': 'Un reçu par e-mail contenant les détails de votre commande a été envoyé à l\'adresse e-mail fournie. Veuillez le conserver pour vos dossiers.',
        'myAccountBtn': 'Accéder à mon Compte',
        'startPageBtn': 'Suite à',
        'bannerText': {
            'part1': 'Profiter davantage de l\'abonnement et installer',
            'part2': 'l\'application',
            'part3': ''
        },
        'bannerBtnText': {
            'version1': 'Vers l\'application',
            'version2': 'Vers l\'application E-Paper'
        }
    },
    'errorPage': {
        'text': "Votre commande n'a malheureusement pas pu être transmise. Merci de nous aider à améliorer l'expérience client en signalant l'erreur à notre service clientèle.",
        'button': 'Réessayez'
    },
    'notFoundPage': {
        'text': 'Le site a déménagé. Cliquez ici pour accéder au <0>shop</0>.'
    },
    'gift': 'cadeau',
    'campagin': 'campagne',
    'maintenanceBanner': {
        'title': 'Travaux de maintenance',
        'text': 'Ce site web est temporairement inaccessible en raison de travaux de maintenance planifiés. Cette interruption ne devrait être que de courte durée. Si vous avez des questions, n’hésitez pas à utiliser notre formulaire de contact pour joindre le service clientèle ou à réessayer ultérieurement. Nous vous remercions de votre compréhension et vous prions de nous excuser pour les désagréments occasionnés.',
        'myAccountBtn': 'Weiter zu Mein Konto',
        'startPageBtn': 'Suite à',
        'contactFormBtn': 'Formulaire de contact'
    }
};
