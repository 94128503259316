import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Button } from '@tampmd/hyperion-ui/Button';
import { DefaultLayout } from '../../layouts/default/DefaultLayout';
import { Footer } from '../../components/Footer/Footer';
import { TenantContext } from '../../hooks/TenantContext';


export const MaintenanceBannerScene = (): JSX.Element =>
{
    const { t } = useTranslation();
    const { tenantState: [ tenant ], responsiveState: [ isMobile ] }: any = useContext(TenantContext);

    return (
        <DefaultLayout colorPalette={tenant.color}>
            <DefaultLayout.Navbar logoUrl={isMobile ? tenant.smallLogo : tenant.logo}/>
            <DefaultLayout.Main>
                <Paper classes={['main-wrapper', 'col-md-12']}>
                    <Paper classes={['maintenance-text-wrapper', 'col-md-6', 'mb-4']}>
                        <Typography classes={['mt-4', 'maintenance-title']} variant="h1">
                            {t('maintenanceBanner.title')}
                        </Typography>
                        <Typography classes={['mt-4']} variant="intro">
                            {t('maintenanceBanner.text')}
                        </Typography>
                        <Button classes={['btn-general', 'mt-4']} autoWidth onClick={() => {
                            window.setTimeout(() => window.location.href = tenant.domainName, 0);
                        }}>
                            {t('maintenanceBanner.contactFormBtn')}
                        </Button>
                    </Paper>
                    <Paper classes={['maintenace-img-wrapper', 'col-md-4', 'mt-4']}>
                        <img
                            src="https://cdn-tamedia.s3.eu-central-1.amazonaws.com/maintenance-banner/OhneWerbungImage.svg"
                            alt="ohne-werbung"
                        />
                    </Paper>
                </Paper>
           
            </DefaultLayout.Main>
            <DefaultLayout.Footer>
                <Footer/>
            </DefaultLayout.Footer>
        </DefaultLayout>
    );
};
