import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckoutScene } from '../scenes/Checkout/CheckoutScene';
import { NotFoundScene } from '../scenes/NotFound/NotFoundScene';
import { CallbackScene } from '../scenes/Callback/Callback';
import { ThankYouScene } from '../scenes/ThankYou/ThankYou';
import { PianoLandingScene } from '../scenes/PianoLanding/PianoLanding';
import { ErrorScene } from '../scenes/Error/Error';
import { AuthenticationContext } from '../hooks/AuthenticationContext';
import { MaintenanceBannerScene } from '../scenes/MaintenanceBanner/MaintenanceBannerScene';
import { useUnleashClient } from '@unleash/proxy-client-react';


export const getGiftTranslationsRegex = (): string => '(gift|geschenk|cadeau)';
export const getCampaginTranslationsRegex = (): string => '(campagin|kampagne|campagne)';

export const Routes = (): JSX.Element | null => {
    const { idToken: auth } = useContext(AuthenticationContext);
    const { t } = useTranslation();
    const client = useUnleashClient();
    const sapMaintenanceOn = client.isEnabled('sap-maintenance');

    useEffect(() => {
        client.start();
    }, []);

    return (
        <Switch>
            <Route exact path="/callback/:offerId" component={CallbackScene} />
            <Route exact path={`/callback/${getGiftTranslationsRegex()}/:offerId`} component={CallbackScene} />
            <Route exact path="/callback/:offerId/:subscriptionId" component={CallbackScene} />
            <Route exact path={`/callback/${getCampaginTranslationsRegex()}/:offerId/:kavz`} component={CallbackScene} />
            <Route exact path="/callback" component={CallbackScene} />
            {auth && (
                <Switch>
                    {sapMaintenanceOn && <Route component={MaintenanceBannerScene} />}
                    <Route exact path="/not-found" component={NotFoundScene} />
                    <Route exact path="/error" component={ErrorScene} />
                    <Route exact path="/thank-you" component={ThankYouScene} />
                    <Route exact path="/" component={PianoLandingScene} />
                    <Route exact path="/:offerId" component={CheckoutScene} />
                    <Route exact path={`/${t('gift')}/:offerId`} component={CheckoutScene} />
                    <Route exact path="/:offerId/:subscriptionId" component={CheckoutScene} />
                    <Route exact path={`/${t('campagin')}/:offerId/:kavz`} component={CheckoutScene} />
                    <Redirect to="/not-found" />
                </Switch>
            )}
        </Switch>
    );
};
