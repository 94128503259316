import { useState, useEffect, useContext, createContext, useMemo } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { TenantContext } from './TenantContext';
import { RedirectionService } from '../services/utils/Redirection/Redirection';

export interface AuthTokenContextProps {
    idToken: string;
    userData: {
        email: string;
        email_verified: false;
        c1_user_id: string;
        idp: 'OneLog' | 'CeleraOne' | 'Piano' | '';
        first_name: string;
        last_name: string;
        iat: number;
        exp: number;
        aud: string;
        iss: string;
        sub: string;
    };
}

export const AuthenticationContext = createContext<AuthContextType>({} as AuthContextType);

AuthenticationContext.displayName = 'Authentication';

export interface AuthContextType {
    idToken: string;
    setIdToken: (token: string) => void;
    isNewUser: boolean;
    setIsNewUser: (newUser: boolean) => void;
}

export const AuthenticationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const cookies = new Cookies();
    const isLoggedInCookie = cookies.get('isLoggedIn');

    const [idToken, setIdToken] = useState('');
    const [isNewUser, setIsNewUser] = useState(false);
    const [, setIsExpired] = useState(false);
    const [, setIsLoggedIn] = useState(Boolean(isLoggedInCookie));
    const { tenantState: [tenant] }: any = useContext(TenantContext);
    const matchCallbackRoute = useRouteMatch('/callback');
    const { pathname } = useLocation();

    const performLogin = (): void => {
        const redirection = new RedirectionService(tenant.id);

        redirection.login(pathname);
    };

    const fetchIdToken = async (): Promise<{ isLoggedIn: boolean, isExpired: boolean }> => {
        const redirection = new RedirectionService(tenant.id);
        const token = await redirection.getToken();

        setIsLoggedIn(true);
        setIdToken(token);

        let isTokenExpired = false;

        if (token) {
            const decodedIdToken: any = jwtDecode(token);

            isTokenExpired = Date.now() >= decodedIdToken.exp * 1000;
            setIsExpired(isTokenExpired);
        }

        return { isLoggedIn: true, isExpired: isTokenExpired };
    };

    useEffect(() => {
        if (tenant.id) {
            //Try to fetch token first
            fetchIdToken().then(res => {
                if (tenant.id && !matchCallbackRoute) {
                    if (!res.isLoggedIn || res.isExpired) {
                        performLogin();
                    }
                }
            }).catch(fErr => {
                if (
                    fErr.response &&
                    (fErr.response.status === 400 || fErr.response.status === 401) &&
                    !matchCallbackRoute) {
                    performLogin();
                }
                setIsLoggedIn(false);
            });
        }
    }, [tenant.id]);

    const authValue = useMemo(() => (
        { idToken, setIdToken, isNewUser, setIsNewUser }
    ), [idToken]);

    return (
        <AuthenticationContext.Provider value={authValue}>
            {children}
        </AuthenticationContext.Provider>
    );
};

